.catalog{
  color: white;
  margin-top: 50px;

  h2{
    font-size: 26px;
  }

  p{
    font-size: 20px;
  }

  .icon_top {
    display: flex;
    margin-top: 50px;

    div{
      text-align: center;
      width: 20%;

      p{
        font-size: 18px;
      }

      img {
        width: 100%;
      }
    }
  }

  .advantages_icon{
      display: flex;
      justify-content: space-between;
      margin-top: 50px;

      div{
        text-align: center;
        width: 20%;

      img {
        width: 60%;
      }

        p {
          font-size: 16px;
        }
    }

  }

  h1{
    text-align: center;

  }
  .catalog_production{
    margin-top: 50px;


    .catalog_image{
      margin-top: 70px;

      justify-content: space-between;
      display: flex;

      .catalog_image_cart{
        text-align: center;



        img{
          width: 90%;
          transition: 1s; /* Время эффекта */
        }

        img:hover{
          transform: scale(1.03); /* Увеличиваем масштаб */
        }
      }
    }
  }

}

@media (max-width: 1000px) {
  .catalog{



    h2{
      font-size: 14px;
    }

    p{
      font-size: 12px;
    }

    .icon_top {
      display: flex;
      margin-top: 50px;

      div{
        text-align: center;
        width: 25%;

        p{
          font-size: 18px;
        }

        img {
          width: 100%;
        }
      }
    }

    .advantages_icon{
      display: flex;
      justify-content: space-between;
      margin-top: 50px;

      div{
        text-align: center;
        width: 20%;

        img {
          width: 60%;
        }

        p {
          font-size: 14px;
        }
      }

    }


    .catalog_production{

      .catalog_image{

        margin-top: 30px;

        .catalog_image_cart{
          text-align: center;

          p{
            font-size: 18px;

          }

        }
      }
    }

  }
}





