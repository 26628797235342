.modal_buttons{
  justify-content: space-between;
  margin: 0 auto;
  margin-bottom: 20px;


  .button_modal{
    width: 200px;
    height: 50px;
    font-size: 18px;
    font-weight: lighter;
    background: none;
    margin-bottom: 20px;
  }

  .button_modal_active{
    border: 2px solid #1ce11c; /* Параметры рамки */
    color: #1ce11c;
  }

  .button_modal_active:hover{
    background: #1ce11c;
    color: white;
  }

  .button_modal_close{
    border: 2px solid red; /* Параметры рамки */
    color: red;
    margin-left: 50px;
  }

  .button_modal_close:hover{
    background: red;
    color: white;
  }
}


.dialog_title{
  display: flex;
  justify-content: space-between;
}

.modal_text{
  padding-left: 10px;
  padding-right: 10px;

}

@media (max-width: 1000px) {
  .modal_buttons{
    .button_modal{
      width: 150px;
      font-size: 20px;
      height: 80px;
    }


  }

  .modal_text{
    h3{
      font-size: 22px;
    }
    p{
      font-size: 14px;
    }

  }

}


