
.content {
  background-color: #000000;

  .content_center {
    max-width: 900px;
    min-height: 800px;
    margin-top: 20px;
    display: flex;
    color: white;
    align-items: center;
  }

  .content_text {
    width: 40%;
    h1{
      font-size: 28px;
    }
    p{
      font-size: 20px;

    }
  }

  .form {
    margin-left: 200px;
    background-color: white;
    height: 430px;
    width: 350px;
    border-radius: 15px;
    color: black;
    padding: 20px;

    .form_input {
      p {
        font-size: 12px;
        margin-bottom: 5px;
      }


    }

    button {
      height: 50px;
      width: 120px;
      border-radius: 25px;
      color: white;
      font-size: 16px;
    }

    button:hover {
      color: black;
    }
  }
}


@media (max-width: 1000px) {
  .content {

    .content_center {

      width: 800px;
      min-height: 800px;
      margin-top: 20px;
      max-width: 900px;
      background-size: 800px 800px;


    }

    .content_text {
      h1 {
        font-size: 20px;
      }
    }

    .form {
      margin-left: 100px;

      height: 450px;
      width: 320px;


      .form_input {
        p {
          font-size: 12px;
          margin-bottom: 5px;
        }


      }

    }
  }
}








