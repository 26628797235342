$sizes: ("xs":320px, "sm":576px, "md":768px, "lg":992px, "xl":1200px);


.header{
  justify-content: space-between;
  display: flex;
  margin-top: 20px;
  align-items: center;



  .logo{
    color: 	#f5e006;
    font-size: 36px;
    font-weight: initial;
  }

  .navigation{
    a{
      color: white;
      margin-left: 20px;
    }
  }
  .img_icon{
    margin-right: 10px;
    a {
      img{
        margin-left: 10px;
        height: 30px;
      }
    }
  }

  .contacts{
    display: flex;
    align-items: center;

    .adress{
      margin-left: 20px;
      color: white;
      align-items: center;

      a{
        margin: 0;
        color: white;
      }

      p{
        margin: 0;
        margin-top: 5px;
      }
    }

  }
}



@media (max-width: 1000px) {
  .header{


    .logo{
      font-size: 30px;
    }

    .navigation{
      display: none;
    }


    .img_icon{
      margin-right: 50px;
      display: flex;
      a {
        img{
          margin-left: 10px;
          height: 46px;
        }
      }
    }

    .contacts{
      display: flex;
      align-items: center;
      a{
        img{
          height: 46px;
        }
      }
      .adress{
        a{
          font-size: 16px;
        }

        p{
          font-size: 16px;
        }
      }

    }
  }
}

