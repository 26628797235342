.advantages_bottom {
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
  align-items: center;

  .contacts {
    color: white;
    width: 30%;

    p {
      margin-top: 20px;
    }

    a {
      color: white;
    }
  }

  .map {
    width: 60%;
  }
}

@media (max-width: 1000px) {
  .advantages_bottom {
    .contacts {

    }
  }
  .map {
    height: 300px;
  }

}




